.special-input {
  height: 100% !important;
  width: 99% !important;
  background: transparent;
  border: none;
  outline: none;
}

.td-with-input {
  height: 32px !important;
  margin: 0 !important;
  padding: 5px !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

.active-tr-sell {
  background-color: #bbdefb !important;
}

.active-tr-sell-error {
  background-color: #ffcdd2 !important;
}

.venta-productos {
  margin-bottom: 0;
}

.venta-productos .col {
  height: calc(100vh - 64px - 70px);
}

.venta-total {
  height: 70px;
  margin-bottom: 0;
}

.venta-total .col {
  height: 70px;
}

.venta-total .col p {
  margin: 0;
}

.venta-total .col .total-number {
  font-size: 20px;
  bottom: 0;
  float: right;
}

/* Table */
.header-fixed {
  width: 100%;
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
  display: block;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
  content: "  ";
  display: block;
  visibility: hidden;
  clear: both;
}

.header-fixed > tbody {
  scrollbar-width: none;
  overflow-y: auto;
  height: calc(100vh - 64px - 70px - 54px) !important;
}

.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
  width: 25% !important;
  float: left;
}

.header-fixed > thead > tr > th {
  white-space: nowrap;
}

  /* Cotizacion */
  .div_reporte {
    width: 750px !important;
    background-color: white;
    font-size: 15px;
  }
  
.cotizacion_header {
  text-align: center;
}

.cotizacion_header .d-block {
  margin-bottom: 2px;
  padding: 0;
  line-height: 15px;
}
